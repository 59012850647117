.oxapay-checkout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--tg-theme-bg-color, #ffffff);
  color: var(--tg-theme-text-color, #000000);
}

.checkout-message {
  background-color: var(--tg-theme-secondary-bg-color, #f0f0f0);
  padding: 10px;
  text-align: center;
}

.checkout-message p {
  margin: 0;
  font-size: 14px;
}

.iframe-container {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.checkout-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--tg-theme-bg-color, rgba(255, 255, 255, 0.8));
  z-index: 1000;
}

.loading-spinner {
  border: 4px solid var(--tg-theme-button-color, #3390ec);
  border-top: 4px solid var(--tg-theme-bg-color, #ffffff);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  padding: 20px;
  text-align: center;
  color: var(--tg-theme-text-color, #000000);
}