/* Main Styles */
.home {
  background-color: #010101;
  color: #ffffff;
  padding: 16px;
  max-width: 100%;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

@keyframes pulseBorder {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
}

.user-balance-card {
  background-color: #010101;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  animation: pulseBorder 2s infinite;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-photo-container {
  position: relative;
  width: 48px;
  height: 48px;
}

.profile-picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.premium-icon {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  z-index: 1;
}

.premium-icon svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));
}

.user-details {
  display: flex;
  flex-direction: column;
}

.welcome {
  font-size: 14px;
  color: #a0a0a0;
}

.username {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.balance-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.balance-label {
  font-size: 14px;
  color: #a0a0a0;
}

.balance-value-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.coin-icon {
  color: #c6f432;
  font-size: 20px;
}

.balance-value {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.average-earnings {
  color: #a0a0a0;
  font-size: 15px;
  margin-bottom: 16px;
  text-align: center;
}

.card-earnings-card,
.card-streak-card,
.card-friends-card {
  background-color: #010101;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #1a1a1a;
}

.chart-container {
  height: 200px;
  position: relative;
}

.card-streak-card h4 {
  color: #ffffff;
  margin-bottom: 12px;
  font-size: 16px;
}

.streak-calendar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.days {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #a0a0a0;
}

.streak-day {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.streak-day.completed {
  color: #26ae5f;
}

.streak-day.missed {
  color: #ff6b6b;
}

.card-friends-card h3 {
  color: #ffffff;
  margin-bottom: 12px;
  font-size: 16px;
}

.friends-list-container {
  overflow-x: auto;
  margin-bottom: 16px;
}

.friends-list {
  display: flex;
  gap: 16px;
  padding-bottom: 8px;
}

.friend-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
}

.friend-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 4px;
}

.friend-coins {
  font-size: 12px;
  color: #ffffff;
}

.invite-button {
  background-color: #027d47;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #010101;
}

.loading-icon {
  --uib-size: 37px;
  --uib-color: #C6F432;
  --uib-speed: .9s;
  --uib-bg-opacity: .1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  overflow: visible;
}

.loading-icon .car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 15, 85;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: travel var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.loading-icon .track {
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes travel {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -100;
  }
}

.loading {
  text-align: center;
  padding: 20px;
  color: #a0a0a0;
}

.error-message {
  text-align: center;
  padding: 16px;
  color: #ff6b6b;
  background-color: #010101;
  border-radius: 16px;
  margin-top: 16px;
  border: 1px solid #1a1a1a;
}

/* Skeleton Loader Styles */
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.skeleton-profile,
.skeleton-balance,
.skeleton-chart,
.skeleton-streak,
.skeleton-friends {
  background-color: #1a1a1a;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.skeleton-profile {
  height: 64px;
  width: 100%;
}

.skeleton-balance {
  height: 40px;
  width: 100%;
}

.skeleton-chart {
  height: 200px;
  width: 100%;
}

.skeleton-streak {
  height: 80px;
  width: 100%;
}

.skeleton-friends {
  height: 100px;
  width: 100%;
}

.skeleton-loader::after,
.skeleton-profile::after,
.skeleton-balance::after,
.skeleton-chart::after,
.skeleton-streak::after,
.skeleton-friends::after {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -150px;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

@media (max-width: 360px) {
  .home {
    padding: 12px;
  }

  .user-balance-card,
  .card-earnings-card,
  .card-streak-card,
  .card-friends-card {
    padding: 12px;
  }

  .profile-photo-container {
    width: 40px;
    height: 40px;
  }

  .premium-icon {
    width: 14px;
    height: 14px;
    top: -3px;
    right: -3px;
  }

  .username {
    font-size: 16px;
  }

  .balance-value {
    font-size: 24px;
  }

  .chart-container {
    height: 180px;
  }

  .streak-day,
  .days {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }

  .friend-avatar {
    width: 40px;
    height: 40px;
  }

  .friend-coins {
    font-size: 10px;
    color: #ffffff;
  }

  .invite-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}