#shy-bird-game {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#shy-bird-game #world {
  width: 100%;
  height: 100%;
}

#shy-bird-game #instructions {
  position: absolute;
  width: 100%;
  bottom: 20px;
  margin: auto;
  font-family: 'Open Sans', sans-serif;
  color: #b75505;
  font-size: 0.9em;
  text-transform: uppercase;
  text-align: center;
}

#shy-bird-game .lightInstructions {
  color: #b59b63;
  font-size: 0.8em;
}

body.shy-bird-game-active {
  overflow: hidden;
}

@media (min-width: 768px) {
  body.shy-bird-game-active::before {
    content: "The Shy Bird Game is designed for mobile devices only.";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    text-align: center;
    padding: 20px;
  }
}