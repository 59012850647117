.payment-processing {
  padding: 20px;
  background-color: #010101;
  min-height: 100vh;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-processing h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #c6f432;
}

.status-message {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
}

.status-steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 300px;
}

.status-step {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.status-step::after {
  content: '';
  position: absolute;
  top: 30px;
  left: 15px;
  width: 2px;
  height: calc(100% + 20px);
  background-color: #333;
  z-index: 1;
}

.status-step:last-child::after {
  display: none;
}

.step-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #666;
  margin-right: 15px;
  position: relative;
  z-index: 2;
}

.status-step.completed .step-icon {
  background-color: #c6f432;
  color: #000000;
}

.status-step.current .step-icon {
  background-color: #ffffff;
  color: #000000;
}

.step-name {
  font-size: 16px;
  color: #ffffff;
}

.fa-spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.check-nfts-button {
  background-color: #c6f432;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.check-nfts-button:hover {
  background-color: #d1f755;
}

.my-nfts-link {
  margin-top: 20px;
  padding: 15px;
  background-color: #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.my-nfts-link p {
  margin: 10px 0;
  color: #ffffff;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.my-nfts-link a {
  color: #c6f432;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.my-nfts-link a:hover {
  text-decoration: underline;
  color: #d1f755;
}