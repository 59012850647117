.game-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #010101;
  z-index: 1001;
}

.coin-pool-balance {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
}

.exit-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.exit-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.lion-game-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #010101;
  overflow: hidden;
  touch-action: none;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 70px; /* Adjust this value based on your header height */
}

.game-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.game-instructions {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}

.lion-game-container {
  flex-grow: 1;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

#world {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .lion-game-wrapper {
    display: none;
  }
}

body.lion-game-active {
  overflow: hidden;
}