.nfts-page {
  padding: 20px;
  background-color: #010101;
  min-height: 100vh;
  color: #ffffff;
}

.wallet-tab-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.wallet-tab-menu .tab-button {
  flex: 1;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 20px;
}

.wallet-tab-menu .tab-button.active {
  background-color: #c6f432;
  color: #000000;
}

.wallet-tab-menu .tab-button:hover:not(.active) {
  background-color: rgba(198, 244, 50, 0.1);
}

.wallet-tab-menu .tab-button.active svg,
.wallet-tab-menu .tab-button.active {
  color: #000000;
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  justify-content: center;
}

.nft-card {
  background-color: #1a1a1a;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nft-card:hover {
  transform: translateY(-5px);
}

.nft-image-container {
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #2c2c2c;
}

.nft-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.nft-info {
  padding: 20px;
  text-align: center;
  width: 100%;
}

.nft-info h3 {
  margin: 0 0 10px;
  font-size: 20px;
  color: #ffffff;
}

.nft-price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #c6f432;
  margin-bottom: 15px;
}

.nft-price svg {
  margin-right: 5px;
}

.benefits-button,
.buy-button,
.compare-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.benefits-button {
  background-color: #2c2c2c;
  color: #ffffff;
}

.benefits-button:hover {
  background-color: #3a3a3a;
}

.buy-button {
  background-color: #c6f432;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy-button:hover {
  background-color: #d7ff4f;
}

.compare-button {
  background-color: #4a4a4a;
  color: #ffffff;
}

.compare-button:hover {
  background-color: #5a5a5a;
}

.buy-button svg,
.compare-button svg {
  margin-right: 8px;
}

.nft-benefits {
  margin-top: 15px;
  text-align: left;
}

.nft-benefits ul {
  padding-left: 20px;
  margin: 0;
}

.nft-benefits li {
  margin-bottom: 5px;
  color: #a0a0a0;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #010101;
}

.loading-icon {
  width: 37px;
  height: 37px;
}

.loading-icon .track,
.loading-icon .car {
  fill: none;
  stroke: #c6f432;
  stroke-width: 5;
}

.loading-icon .car {
  stroke-dasharray: 15, 85;
  animation: travel 0.9s linear infinite;
}

@keyframes travel {
  to {
    stroke-dashoffset: -100;
  }
}

.error-message {
  text-align: center;
  padding: 20px;
  background-color: #ff6b6b;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
}

.checkout-modal,
.comparison-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.checkout-content,
.comparison-content {
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 16px;
  text-align: center;
  max-width: 90%;
  width: 600px;
}

.checkout-image-container {
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
}

.checkout-nft-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.checkout-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancel-button,
.confirm-button,
.close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button,
.close-button {
  background-color: #2c2c2c;
  color: #ffffff;
}

.cancel-button:hover,
.close-button:hover {
  background-color: #3a3a3a;
}

.confirm-button {
  background-color: #c6f432;
  color: #000000;
}

.confirm-button:hover {
  background-color: #d7ff4f;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.comparison-table th,
.comparison-table td {
  padding: 10px;
  border: 1px solid #2c2c2c;
  text-align: center;
}

.comparison-table th {
  background-color: #2c2c2c;
  color: #ffffff;
}

@media (max-width: 768px) {
  .wallet-tab-menu {
    max-width: 100%;
  }

  .wallet-tab-menu .tab-button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .nft-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  .checkout-content,
  .comparison-content {
    padding: 20px;
  }

  .checkout-image-container {
    width: 150px;
    height: 150px;
  }

  .comparison-table {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .nfts-page {
    padding: 10px;
  }

  .wallet-tab-menu .tab-button {
    font-size: 12px;
    padding: 8px 12px;
  }
}

/* Skeleton Loader Styles */
.skeleton-loader {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  justify-content: center;
}

.skeleton-nft-card {
  background-color: #1a1a1a;
  border-radius: 16px;
  height: 360px;
  position: relative;
  overflow: hidden;
}

.skeleton-nft-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -150px;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}