.bottom-nav {
  --local-bg-color: #010101;
  --local-text-color: #6b7280;
  --local-active-color: #c6f432;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--local-bg-color);
  box-shadow: 0 0px 11px #383838;
  padding: 10px 0;
  border-radius: 30px 30px 0 0; /* Rounded top corners */
  z-index: 10000000;
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--local-text-color);
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 17px;
  height: 50px; /* Fixed height for consistent spacing */
}

.bottom-nav-item.active {
  color: var(--local-active-color);
}

.bottom-nav-item:hover {
  color: var(--local-active-color);
}

.bottom-nav-item svg {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.bottom-nav-item .nav-label {
  font-size: 12px;
  margin-top: 4px;
  display: none; /* Hide by default */
  font-family: 'Circular Std', sans-serif;
}

.bottom-nav-item.active .nav-label {
  display: block; /* Show only when active */
}