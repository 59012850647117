@font-face {
  font-family: 'Circular Std';
  src: url('./fonts/CircularStd-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std';
  src: url('./fonts/CircularStd-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

:root {
  --tg-theme-bg-color: #010101;
  --tg-theme-text-color: #000000;
  --tg-theme-button-color: #c6f432;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f0f2f5;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Circular Std', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: var(--tg-theme-text-color);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 70px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'Circular Std', sans-serif;
  font-weight: bold;
}

.card {
  background-color: var(--tg-theme-secondary-bg-color);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button {
  background-color: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: 'Circular Std', sans-serif;
}

.button:hover {
  background-color: darken(var(--tg-theme-button-color), 10%);
}

@media (min-width: 768px) {
  body::before {
    content: "This app is designed for mobile devices only.";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    text-align: center;
    padding: 20px;
    font-family: 'Circular Std', sans-serif;
  }

  .App {
    display: none;
  }
}

body.lion-game-active {
  overflow: hidden;
}

@media (min-width: 768px) {
  body.lion-game-active::before {
    content: "The Lion Game is designed for mobile devices only.";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    text-align: center;
    padding: 20px;
    font-family: 'Circular Std', sans-serif;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  width: 400px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.modal-actions button {
  margin-left: 0.5rem;
}