.my-nfts-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #010101;
}

.notification {
  background-color: #000000;
  color: #ffffff;
  padding: 15px 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.notification p {
  margin: 0;
}

.notification strong {
  color: #c6f432;
}

.my-nfts {
  padding: 20px;
  color: #ffffff;
  flex-grow: 1;
}

.my-nfts h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #c6f432;
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.nft-item {
  background-color: #1a1a1a;
  border-radius: 16px;
  padding: 15px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.nft-image-container {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #2c2c2c;
  border-radius: 8px;
  margin-bottom: 10px;
}

.nft-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.nft-item h3 {
  margin: 10px 0;
  font-size: 18px;
  color: #ffffff;
}

.nft-item p {
  margin: 5px 0;
  color: #a0a0a0;
}

.token-ids {
  font-size: 12px;
  word-break: break-all;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #010101;
}

.loading-icon {
  --uib-size: 37px;
  --uib-color: #C6F432;
  --uib-speed: .9s;
  --uib-bg-opacity: .1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  overflow: visible;
}

.loading-icon .car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 15, 85;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: travel var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.loading-icon .track {
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes travel {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -100;
  }
}

.no-nfts {
  text-align: center;
  padding: 20px;
  color: #a0a0a0;
  font-size: 18px;
}

@media (max-width: 768px) {
  .nft-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .nft-item h3 {
    font-size: 16px;
  }

  .nft-item p {
    font-size: 14px;
  }

  .token-ids {
    font-size: 10px;
  }

  .nft-image-container {
    height: 150px;
  }
}

/* Skeleton Loader Styles */
.skeleton-loader {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.skeleton-nft-item {
  background-color: #1a1a1a;
  border-radius: 16px;
  height: 250px;
  position: relative;
  overflow: hidden;
}

.skeleton-nft-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -150px;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}