@keyframes whiteShimmer {
  0%, 100% {
    border-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
}

@keyframes shine {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes giftBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.daily-bonus-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.bonus-card {
  position: relative;
  background-color: #1a1a1a;
  border-radius: 16px;
  padding: 20px;
  width: 75%;
  max-width: 280px;
  color: #ffffff;
  text-align: center;
  animation: fadeIn 0.5s ease-out, whiteShimmer 3s ease-in-out infinite;
  border: 2px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.bonus-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: linear-gradient(45deg, 
    rgba(255,255,255,0) 0%, 
    rgba(255,255,255,0.05) 25%, 
    rgba(255,255,255,0.1) 50%, 
    rgba(255,255,255,0.05) 75%, 
    rgba(255,255,255,0) 100%
  );
  transform: rotate(45deg);
  animation: shine 3s linear infinite;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.close-button:hover {
  color: rgba(255, 255, 255, 0.7);
}

.bonus-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 15px;
  animation: giftBounce 2s ease-in-out infinite;
}

.bonus-title {
  font-size: 20px;
  margin-bottom: 5px;
  color: #ffffff;
  font-weight: bold;
}

.bonus-subtitle {
  font-size: 12px;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.7);
}

.bonus-rewards {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.reward-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reward-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.reward-value {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.reward-label {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.7);
}

.claim-button {
  background-color: #c6f432;
  border: none;
  color: #000000;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.3s ease;
  width: 100%;
  position: relative;
  z-index: 10;
}

.claim-button:hover {
  background-color: #d7ff4f;
}

.claim-button:disabled {
  background-color: #4a4a4a;
  color: #8a8a8a;
  cursor: not-allowed;
}

.loading,
.error-message {
  color: #ffffff;
  text-align: center;
}

.error-message h2 {
  color: #ff6b6b;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}