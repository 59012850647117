.tasks-page {
  background-color: #010101;
  color: #000;
  padding: 0px;
  min-height: 100vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tasks-header {
  height: 270px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #010101;
}

.progress-circle {
  width: 120px;
  height: 120px;
  position: relative;
  background-color: #016b6a;
  border-radius: 50%;
  overflow: hidden;
}

.progress-circle::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background-color: #010101;
  border-radius: 50%;
  z-index: 2;
}

.circular-chart {
  width: 100%;
  height: 100%;
}

.circle-bg {
  fill: none;
  stroke: #ffffff;
  stroke-width: 5;
}

.circle {
  fill: none;
  stroke-width: 5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
  stroke: #c6f432;
}

.progress-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 3;
}

.progress-icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: #F8F9D3;
  font-size: 18px;
}

.progress-number {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.progress-total {
  font-size: 14px;
  color: #ffffff;
}

.coins-earned {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
}

.coins-earned span:first-child {
  font-size: 24px;
  font-weight: bold;
}

.coins-earned span:last-child {
  font-size: 14px;
  color: #666;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffffff;
}

.category-tabs {
  display: flex;
  overflow-x: auto;
  margin-bottom: 20px;
}

.category-tab {
  background: none;
  border: none;
  color: #888;
  font-size: 1.2em;
  padding: 10px 15px;
  cursor: pointer;
  white-space: nowrap;
}

.category-tab.active {
  color: #fffff;
  font-weight: bold;
}

.task-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px 15px;
}

.task-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.task-reward {
  background-color: #c6f432;
  color: #000000;
  border-radius: 30px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 70px;
  height: 30px;
  flex-shrink: 0;
}

.task-reward svg {
  margin-right: 4px;
}

.task-content {
  flex-grow: 1;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.task-content h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
}

.task-content p {
  margin: 5px 0 0;
  font-size: 0.875rem;
  color: #666;
}

.task-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.task-action-button {
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.task-action-button .button-text {
  display: inline-block;
  transition: opacity 0.3s ease;
}

.task-action-button .loading-dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.task-action-button.loading .button-text {
  opacity: 0;
}

.task-action-button.loading .loading-dots {
  opacity: 1;
}

.loading-dots .dot {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  margin: 0 2px;
  animation: dot-pulse 1.5s infinite ease-in-out;
}

.loading-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-pulse {
  0%, 60%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(0.5);
    opacity: 0.5;
  }
}

.task-action-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.task-action-button.start {
  background-color: #007bff;
}

.task-action-button.start:hover:not(:disabled) {
  background-color: #0056b3;
}

.task-action-button.claim {
  background-color: #28a745;
}

.task-action-button.claim:hover:not(:disabled) {
  background-color: #218838;
}

.task-action-button.retry {
  background-color: #ffc107;
  color: #000;
}

.task-action-button.retry:hover:not(:disabled) {
  background-color: #e0a800;
}

.task-status.completed {
  width: 24px;
  height: 24px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.new-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff4500;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  font-weight: bold;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

/* Skeleton loader styles */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  background: #1a1a1a;
  border-radius: 8px;
}

.skeleton-box {
  background: linear-gradient(to right, #1a1a1a 4%, #2a2a2a 25%, #1a1a1a 36%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

.task-item.skeleton {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 15px;
}

.task-item.skeleton .task-reward {
  width: 70px;
  height: 30px;
}

.task-item.skeleton .task-content {
  flex-grow: 1;
  margin-left: 15px;
}

.task-item.skeleton .task-actions {
  margin-left: auto;
}