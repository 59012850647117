/* src/styles/ErrorPage.css */
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #010101;
  color: #ffffff;
  padding: 20px;
}

.error-page h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.error-page p {
  font-size: 16px;
  margin-bottom: 10px;
}

.error-page button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #c6f432;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.error-page button:hover {
  background-color: #d1f755;
}