.games-page-container {
  background-color: #000000;
  min-height: 100vh;
  padding: 20px;
  color: white;
  max-width: 800px;
  margin: 0 auto;
}

.games-page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.games-balance-meter {
  position: relative;
  width: 50px;
  height: 70px;
}

.potion {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #FFF;
  overflow: hidden;
  position: relative;
  background-color: #2b2b2b;
}

.potion-liquid {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--fill-percentage, 50%);
  background-color: #c6f432;
  transition: height 0.5s ease;
}

.potion-liquid::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 40%;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.games-balance-display {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: #e0e0e0;
  white-space: nowrap;
}

.game-pass-ticket {
  display: flex;
  width: 100px;
  height: 40px;
  overflow: hidden;
  border-radius: 5px;
}

.ticket-left {
  background-color: #c6f432;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pass-number {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

.ticket-right {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pass-title {
  font-size: 12px;
  color: #000;
  font-weight: bold;
}

.games-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.games-card {
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.games-card-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.games-play-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #c6f432;
  color: #000000;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;
}

.send-icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.invite-friend {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.invite-friend span {
  margin-bottom: 10px;
  text-align: center;
}

.invite-button {
  background-color: #c6f432;
  color: #000000;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
}

.loading-icon {
  --uib-size: 37px;
  --uib-color: #C6F432;
  --uib-speed: .9s;
  --uib-bg-opacity: .1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  overflow: visible;
}

.loading-icon .car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 15, 85;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: travel var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.loading-icon .track {
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes travel {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -100;
  }
}

.games-error-message {
  text-align: center;
  padding: 20px;
  color: #ff4d4d;
}

@media (max-width: 480px) {
  .games-list-container {
    grid-template-columns: 1fr;
  }
}

/* Skeleton Loader Styles */
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.skeleton-game-card {
  background-color: #1a1a1a;
  border-radius: 24px;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.skeleton-game-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -150px;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}