.screen-3 {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #010101;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  font-family: 'Circular Std', sans-serif;
  color: #ffffff;
  padding: 20px;
}

.wallet-tab-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 400px;
}

.wallet-tab-menu .tab-button {
  flex: 1;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 20px;
}

.wallet-tab-menu .tab-button.active {
  background-color: #c6f432;
  color: #000000;
}

.wallet-tab-menu .tab-button:hover:not(.active) {
  background-color: rgba(198, 244, 50, 0.1);
}

.wallet-tab-menu .tab-button.active svg,
.wallet-tab-menu .tab-button.active {
  color: #000000;
}

.frame-parent {
  width: 100%;
  max-width: 600px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.frame-item {
  background-color: #ffffff;
  width: 75%;
  aspect-ratio: 1.78 / 1;
  position: relative;
  border-radius: 16px;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  color: #000000;
  margin: 0 auto 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.ton-logo-image {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ton-logo {
  font-size: 24px;
  font-weight: bold;
  color: #0a3d62;
  margin-bottom: 20px;
}

.connect-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.card-bottom {
  background-color: #1c2e3e;
  padding: 15px 20px;
  margin: 20px -20px -20px -20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.wallet-provider {
  display: flex;
  align-items: center;
}

.provider-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.provider-name {
  font-size: 14px;
  color: #ffffff;
}

.airdrop-eligibility {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000000;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.airdrop-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.eligibility-grid {
  display: grid;
  gap: 15px;
}

.rumor-source {
  align-self: stretch;
  border-radius: 24px;
  background-color: #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 15px;
  gap: 27px;
  min-height: 68px;
}

.rumor-topic {
  border-radius: 100px;
  background-color: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 36px;
  height: 36px;
}

.rumor-2-icon {
  width: 20px;
  height: 20px;
}

.inspiration-request {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.optional-tag {
  font-size: 0.8em;
  background-color: #f0f0f0;
  color: #666;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 5px;
  font-weight: normal;
}

.i-need-some {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 4px;
}

.inspiration-request p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  margin: 0;
}

@media (max-width: 768px) {
  .wallet-tab-menu {
    max-width: 100%;
  }

  .wallet-tab-menu .tab-button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .frame-item {
    width: 90%;
  }

  .airdrop-eligibility {
    padding: 15px;
  }

  .rumor-source {
    padding: 10px;
    gap: 15px;
  }

  .rumor-topic {
    width: 30px;
    height: 30px;
  }

  .i-need-some {
    font-size: 14px;
  }

  .inspiration-request p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .screen-3 {
    padding: 10px;
  }

  .wallet-tab-menu .tab-button {
    font-size: 12px;
    padding: 8px 12px;
  }

  .frame-item {
    width: 95%;
  }

  .ton-logo-image {
    width: 50px;
    height: 50px;
  }

  .ton-logo {
    font-size: 20px;
  }

  .airdrop-title {
    font-size: 16px;
  }
}