/* EdgewareWallet.css */
.edgeware-wallet-page {
  width: 100%;
  min-height: 100vh;
  background-color: #010101;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Circular Std', sans-serif;
  color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
}

.loading-screen,
.loading-overlay,
.processing-modal-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Cover entire viewport */
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(1, 1, 1, 0.8);
  z-index: 9999;
}

.loading-icon {
  --uib-size: 100px;
  --uib-color: #C6F432;
  --uib-speed: .9s;
  --uib-bg-opacity: .1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  overflow: visible;
}

.loading-icon .car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 15, 85;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: travel var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.loading-icon .track {
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes travel {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -100;
  }
}

.loading-overlay p,
.processing-content p {
  margin-top: 20px;
  color: #ffffff;
  font-size: 18px;
}

/* Rest of your CSS styles */
.frame-item {
  background-color: #ffffff;
  width: 100%;
  max-width: 400px;
  border-radius: 16px;
  padding: 30px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000000;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.edgeware-logo-image {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.wallet-balance {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.balance-amount {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.balance-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.balance-details p {
  margin: 0;
  font-size: 18px;
}

.balance-details span {
  font-weight: bold;
  margin-right: 5px;
}

.wallet-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2a2a2a;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  width: 30%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover,
.action-button.active {
  background-color: #c6f432;
  color: #000000;
}

.action-button svg {
  font-size: 24px;
  margin-bottom: 5px;
}

.send-form,
.receive-info,
.stake-form {
  background-color: #1e1e1e;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input,
select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3a3a3a;
  background-color: #2a2a2a;
  color: #ffffff;
}

button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #c6f432;
  color: #000000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #d1f755;
}

button:disabled {
  background-color: #4a4a4a;
  color: #8a8a8a;
  cursor: not-allowed;
}

.input-with-max {
  display: flex;
  align-items: center;
}

.input-with-max input {
  flex-grow: 1;
  margin-right: 10px;
}

.input-with-max button {
  padding: 5px 10px;
  background-color: #3a3a3a;
  color: #ffffff;
}

.address-type-selector,
.address-type-switch {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  background-color: #2a2a2a;
  border-radius: 5px;
  padding: 5px;
}

.address-type-selector button,
.address-type-switch button {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.address-type-selector button.active,
.address-type-switch button.active {
  background-color: #c6f432;
  color: #000000;
  border-radius: 3px;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.address-container {
  text-align: center;
}

.address-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.address {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  word-break: break-all;
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 5px;
}

.copy-button {
  background: none;
  border: none;
  color: #c6f432;
  cursor: pointer;
  padding: 5px;
}

.transaction-list {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #2a2a2a;
  transition: background-color 0.3s ease;
}

.transaction-item:last-child {
  border-bottom: none;
}

.transaction-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.transaction-icon {
  font-size: 20px;
}

.transaction-info {
  display: flex;
  flex-direction: column;
}

.transaction-type {
  font-weight: bold;
}

.transaction-amount {
  color: #c6f432;
}

.transaction-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.transaction-status {
  font-style: italic;
}

.transaction-time {
  font-size: 0.8em;
  color: #888;
}

.explorer-link {
  color: #c6f432;
  text-decoration: none;
}

.explorer-link:hover {
  text-decoration: underline;
}

.confirmation-modal,
.result-modal,
.processing-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
  width: 300px;
  z-index: 1001;
}

.confirmation-modal h2,
.result-modal h2,
.processing-modal h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.confirmation-modal p,
.result-modal p,
.processing-modal p {
  margin: 10px 0;
}

.confirmation-modal-overlay,
.result-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirmation-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.confirmation-buttons button:first-child {
  background-color: #4CAF50;
  color: #000000;
}

.confirmation-buttons button:last-child {
  background-color: #f44336;
  color: #000000;
}

.confirmation-buttons .loading-icon {
  width: 20px;
  height: 20px;
}

.warning {
  color: #ff9800;
  font-weight: bold;
  margin-top: 10px;
}

.result-content {
  text-align: center;
}

.result-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.result-icon.success {
  color: #4CAF50;
}

.error-icon {
  background-color: #ff4136;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}

.error-icon svg {
  font-size: 32px;
  color: white;
}

.processing-message {
  font-style: italic;
  color: #888;
  margin-top: 10px;
}

.loading,
.no-more-transactions {
  text-align: center;
  padding: 10px;
  color: #888;
}

.back-to-wallet-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #c6f432;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.back-to-wallet-button:hover {
  background-color: #d1f755;
}

.processing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.processing-content h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  .frame-item,
  .send-form,
  .receive-info,
  .stake-form,
  .transaction-list {
    max-width: 100%;
  }

  .balance-amount {
    font-size: 28px;
  }

  .balance-details p {
    font-size: 16px;
  }

  .action-button {
    padding: 10px;
  }

  .action-button svg {
    font-size: 20px;
  }

  .confirmation-modal,
  .result-modal,
  .processing-modal {
    width: 90%;
  }
}