.share-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.share-dialog {
  background-color: #010101;
  padding: 20px;
  border-radius: 16px;
  width: 85%;
  max-width: 320px;
  color: white;
  font-size: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #1a1a1a;
  position: relative;
}

.gift {
  text-align: center;
  margin-bottom: 15px;
  color: gold;
}

.share-dialog h2 {
  text-align: center;
  margin: 0 0 15px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.invite-details {
  margin-bottom: 20px;
  background-color: #171717;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #1a1a1a;
}

.invite-details p {
  margin: 0 0 10px;
  font-weight: bold;
  color: #ffffff;
}

.invite-details ul {
  margin: 0;
  padding-left: 20px;
  color: #a0a0a0;
}

.invite-details li {
  margin-bottom: 5px;
}

.share-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  background-color: #171717;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #1a1a1a;
}

.share-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.3s;
  color: #ffffff;
  font-size: 12px;
}

.share-option:hover {
  transform: scale(1.1);
}

.share-option:focus {
  outline: none;
}

.share-option:active {
  transform: scale(0.95);
}

.share-icon {
  margin-bottom: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

.share-option:hover .share-icon {
  opacity: 0.8;
}

.share-icon svg {
  width: 24px;
  height: 24px;
  fill: white;
}

.share-icon-telegram {
  background-color: #0088cc;
}

.share-icon-copy {
  background-color: #6e6e6e;
}

.share-icon-whatsapp {
  background-color: #25D366;
}

.share-icon-x {
  background-color: #000000;
}

.share-icon-facebook {
  background-color: #1877F2;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button:hover {
  color: #a0a0a0;
}

/* Ensure buttons don't change color when active */
.share-option:active,
.share-option:focus {
  background-color: transparent;
  color: #ffffff;
}

/* Ensure icon colors don't change when clicked */
.share-option:active .share-icon,
.share-option:focus .share-icon {
  opacity: 1;
}

/* Specific hover effects for each icon */
.share-option:hover .share-icon-telegram {
  background-color: #0099e5;
}

.share-option:hover .share-icon-copy {
  background-color: #808080;
}

.share-option:hover .share-icon-whatsapp {
  background-color: #28e46e;
}

.share-option:hover .share-icon-x {
  background-color: #1a1a1a;
}

.share-option:hover .share-icon-facebook {
  background-color: #1877F2;
}