.rabbit-game-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #004d40;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.rabbit-game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 1001;
}

.rabbit-game-timer {
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 8px 15px;
  border-radius: 20px;
  backdrop-filter: blur(5px);
}

.rabbit-game-exit-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  backdrop-filter: blur(5px);
}

.rabbit-game-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.rabbit-game-instructions {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}

.rabbit-game-container {
  flex-grow: 1;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.rabbit-game-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.rabbit-game-time-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px 40px;
  border-radius: 10px;
  font-size: 24px;
  z-index: 1000;
}

.rabbit-game-over-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #010101;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  text-align: center;
  max-width: 90%;
  width: 300px;
  z-index: 1001;
}

.rabbit-game-over-popup h2,
.rabbit-game-over-popup p {
  color: #ffffff;
  margin: 10px 0;
}

.rabbit-game-over-popup h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.rabbit-game-over-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.rabbit-game-over-button {
  background-color: #c6f432;
  color: #010101;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;
}

.rabbit-game-over-button:last-child {
  margin-bottom: 0;
}

.rabbit-game-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #004d40;
  color: white;
  font-size: 24px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
}

.loading-icon {
  --uib-size: 37px;
  --uib-color: #C6F432;
  --uib-speed: .9s;
  --uib-bg-opacity: .1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  overflow: visible;
}

.loading-icon .car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 15, 85;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: travel var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.loading-icon .track {
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes travel {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -100;
  }
}

.game-over-popup {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  width: 300px;
}

.game-over-popup h2 {
  color: white;
  margin-top: 0;
}

.game-over-popup p {
  color: white;
  margin-bottom: 10px;
}

.game-over-popup button {
  background-color: #c6f432;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.game-over-popup .invite-friends {
  margin-top: 20px;
}