.leaderboard {
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
  background-color: #010101;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.leaderboard-section {
  background-color: #171717;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
}

.leaderboard-section h2 {
  font-size: 18px;
  margin-bottom: 12px;
  color: #ffffff;
}

.leaderboard-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #1e1e1e;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}

.leaderboard-item:hover {
  transform: translateY(-2px);
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #ffffff;
  margin-right: 15px;
  font-size: 18px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.username {
  font-weight: bold;
  margin-bottom: 3px;
  color: #ffffff;
  font-size: 16px;
}

.coins {
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.coins .icon {
  margin-right: 5px;
  color: #c6f432;
}

.rank {
  font-weight: bold;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f0f2f5;
}

.rank.gold {
  background-color: #ffd700;
  color: #ffffff;
}

.rank.silver {
  background-color: #c0c0c0;
  color: #ffffff;
}

.rank.bronze {
  background-color: #cd7f32;
  color: #ffffff;
}

.rank svg {
  width: 20px;
  height: 20px;
  color: #ffffff;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #010101;
}

.loading-icon {
  --uib-size: 37px;
  --uib-color: #C6F432;
  --uib-speed: .9s;
  --uib-bg-opacity: .1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  overflow: visible;
}

.loading-icon .car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 15, 85;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: travel var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.loading-icon .track {
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes travel {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -100;
  }
}

.error-message {
  text-align: center;
  padding: 16px;
  color: #ff6b6b;
  background-color: #171717;
  border-radius: 16px;
  margin-top: 16px;
}

@media (max-width: 600px) {
  .leaderboard {
    padding: 12px;
  }

  .leaderboard-section {
    padding: 12px;
  }

  .leaderboard-section h2 {
    font-size: 16px;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }

  .username {
    font-size: 14px;
  }

  .coins {
    font-size: 12px;
  }

  .rank {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }
}